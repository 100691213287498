<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function () {
    return { 
      slider: null,
      hideLeftShadow: false,
      isImgLoaded: false
    };
  },
  mounted() {
    Vue.nextTick(() => {
      this.mountCarousel();
    });
  },
  methods: {
    onImgLoad() {
      if (!this.isLoaded && this.slider) {
        this.isLoaded = true;
        this.slider.resize();
      }
    },
    mountCarousel() {
      const centeredTileSkins = ["1"];
      const slideConfig =  {
        draggable: "> 1",
        prevNextButtons: true,
        contain: true,
        pageDots: true,
        cellAlign: (centeredTileSkins.indexOf(this.skin) >= 0 ? 'center' : 'left'),
        wrapAround: (centeredTileSkins.indexOf(this.skin) >= 0 ? true : false),
        groupCells: true
      }

      this.slider = new Flickity(this.$refs["flickity-carousel"], slideConfig);

      this.slider.on("dragStart", () => {
        $(this.$refs["flickity-carousel"]).find('.flickity-carousel-item').css('pointer-events', 'none');
      });

      this.slider.on("dragEnd", () => {
        $(this.$refs["flickity-carousel"]).find('.flickity-carousel-item').css('pointer-events', 'all');
      });

      if (["2", "3"].indexOf(this.skin) > -1) {
        this.hideLeftShadow = true;
      }

      this.slider.on("change", (index) => {
        if (["2", "3"].indexOf(this.skin) > -1) {
          this.hideLeftShadow = !index;
        }
      });
    }
  },
  computed: {
    alignment() {
      return get(this.content, "text_align", "center");
    },
    skin() {
      return this.content.skin || "1";
    },
    skinSet() {
      const classes = [`stripe-carousel__skin-${this.skin}`];
      return classes;
    },
    rootStyle() {
      return {
        "background-image": this.getContentResponsiveImageURL("background_image")
      };
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="[contentType, paddingY]" :style="[rootStyle, theme]" :id="anchorId" class="themed-item" stripe-carousel-list-component>
    <div class="container position-relative">
      <div class="flickity-carousel" ref="flickity-carousel" :class="[skinSet, {'hide-left-shadow': hideLeftShadow}]">
        <div class="flickity-carousel-item my-5" v-for="(item, index) in content.items" :key="`carousel-slide-${index}`" :child_name="item.title">
          <img v-if="item.image" :src="item.image.url" :alt="item.title" @load="onImgLoad" class="px-4">
          <div class="row w-100 justify-content-center" :class="`text-${alignment}`">
            <div class="col-12 col-md-8 mt-4 px-4">
              <i v-if="content.icon" :class="content.icon" :style="{'color': `${item.text_color}`}"></i>
              <component v-if="item.title" :class="item.title_class" :is="item.title_tag || 'h2'" :style="{'color': item.text_color}" title>
                {{item.title}}
              </component>
              <component v-if="item.description" :is="item.description_tag || 'p'" :class="item.description_class" :style="{'color': `${item.text_color}`}" description v-html="item.description"></component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-carousel-list-component] {
  position: relative;
  background-size: 100% auto;

  .flickity-viewport:focus, .flickity-carousel:focus {
    outline: none !important;
  }

  .flickity-button.flickity-prev-next-button {
    z-index: 2;
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    background: transparent;
    width: 56px;
    height: 56px;
  }

  .flickity-button.flickity-prev-next-button:disabled {
    display: none;
  }

  .flickity-button-icon {
    transform: scale(.65);
  }

  .flickity-page-dots {
    padding-bottom: 3rem;
    position: relative;
    bottom: 0;

    .dot {
      width: 16px;
      height: 16px;
      background: var(--theme-color);
      opacity: 0.35;
      
      &.is-selected {
        opacity: 1;
      }
    }
  }

  .flickity-carousel-item {
    padding-left: 1rem;
    padding-right: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 600px;
      max-width: 100%;
    }

    > div {
      position: relative;
    }
  }

  .stripe-carousel__skin-1 .flickity-carousel-item {
    width: 100%;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  @include media-breakpoint-down(sm) {

    .flickity-viewport::after,
    .flickity-viewport::before {
      width: 2rem;
    }

    .stripe-carousel__skin-1 .flickity-carousel-item {
      padding-left: 0rem;
      padding-right: 0rem;
      width: 100%;
    }

    .flickity-button.flickity-prev-next-button {
      display: none;
    }

  }
}
</style>
