<script>
import { get, debounce } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      gradientBackground: false,
      sidebar: false
    };
  },
  mounted() {
    this.setScrollListener();
  },
  watch: {
    isFixed(newVal) {
      if (!!newVal) {
        this.setScrollListener();
      } else {
        $(window).off('scroll');
      }
    }
  },
  methods: {
    getItemUrl(item) {
      if (item.url.url.startsWith("#")) {
        return "javascript: void(0)";
      } else if (this.content.preserve_url_params) {
        return item.url.url + '?' + window.location.search.substr(1);
      } else {
        return item.url.url;
      }
    },
    mayAnchorTo(item, event) {
      if (item.url.url.startsWith("#")) {
          let offset = $("#main-navbar").outerHeight();
          if (this.content.sticky) {
            offset += $(this.$el).outerHeight();
          }

          const position = $(item.url.url).offset().top - offset;
          $("html, body").animate({ scrollTop: position }, 250, "linear");

         event.preventDefault();
         return false;
      } else {
        return true;
      }
    },
    toggleSidebar() {
      if (this.sidebar) {
        clearAllBodyScrollLocks();
        this.sidebar = false;
      } else {
        disableBodyScroll(this.$refs["sidebar"]);
        this.sidebar = true;
      }
    },
    setScrollListener() {
      Vue.nextTick(() => {
        if (this.isFixed) {
          $(window).on('scroll', this.scrollListenerHandler());
        }
      });
    },
    scrollListenerHandler() {
      return debounce(() => {
        if (window.scrollY == 0) {
          this.gradientBackground = false;
        } else {
          this.gradientBackground = true;
        }
      })
    }
  },
  computed: {
    isFixed() {
      const sticky = this.pageModel.name_to_content[this.pageModel.main_content_name].sticky;
      return this.isMobile ? false : !sticky;
    },
    isMobile() {
      return globalState.viewport.xs || globalState.viewport.sm;
    },
    items() {
      return get(globalState, ["pageModel", "layout", "header", "navbar_items"], []); 
    },
    logoAlt() {
      return get(this.pageModel, ['seo', 'title'], '');
    },
    rootClasses() {
      return {
        'fixed-top': this.isFixed,
        'sticky-top': !this.isFixed
      }
    },
    navbarClasses() {
      return {
        'bg-gradients': this.gradientBackground || !this.isFixed
      }
    }
  },
};
</script>

<template>
  <div :class="rootClasses" class="w-limiter">
    <nav id="main-navbar" class="navbar navbar-expand-lg justify-content-between px-3 px-md-5" :class="navbarClasses" navbar-component>
      <div class="d-flex">
        <div v-if="items.length > 0" class="d-flex d-lg-none justify-content-center align-items-center mr-4 text-white cursor-pointer" @click="toggleSidebar">
          <i class="fal fa-bars fa-2x"></i>
        </div>
        <a class="navbar-brand" :href="applyContextToUrl('/')">
          <img :src="content.logo.url" class="navbar-logo" :alt="logoAlt"/>
        </a>
      </div>
      <div class="d-lg-flex d-none justify-content-end mx-n4">
        <div class="nav-item px-4" v-for="(item, index) in items">
          <a class="nav-item-url" :target="getTarget(item.url)" :href="getItemUrl(item)" @click="mayAnchorTo(item, $event)">{{item.url.title}}</a>
        </div>
      </div>
    </nav>
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div class="position-fixed" @click="toggleSidebar" v-if="sidebar" sidebar-layer></div>
    </transition>
    <transition enter-active-class="animated faster slideInLeft" leave-active-class="animated faster slideOutLeft">
      <div ref="sidebar" v-if="sidebar" class="position-fixed bg-dark text-white border-right" sidebar>
        <div class="close-icon position-absolute p-4 cursor-pointer">
          <i class="fal fa-times fa-2x" @click="toggleSidebar"></i>
        </div>

        <div class="d-flex flex-column h-100vh">
          <div class="flex-grow-1 sidebar-content">
            <div class="py-5">
              <div class="py-3 border-bottom nav-item" v-for="(item, index) in items" :key="`sidebar-item-${index}`">
                <a class="nav-item-url text-white font-weight-bold d-flex align-items-center justify-content-between" :href="getItemUrl(item)" :target="getTarget(item.url)" @click="mayAnchorTo(item, $event)">
                  {{item.url.title}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
[navbar-component] {
  min-height: $header-height-sm;
  transition: background $animation-time-fast linear;

  &.bg-gradients {
    background: $primary;
  }

  @include media-breakpoint-up(md) {
    background: transparent;
  }

  .navbar-brand {
    min-height: calc(#{$header-height-sm} - 1rem);
  }

  .navbar-logo {
    height: 2.75rem;

    @include media-breakpoint-up(md) {
      height: 4rem;
    }
  }

  .sidebar-toggler, .close-icon {
    transition: color $animation-time-fast;
    
    &:hover {
      opacity: 0.75;
    }
  }
}

[navbar-component] .nav-item,
[sidebar] .nav-item {
  .nav-item-url {
    color: $white;
    transition: color $animation-time-fast;
  }

  &:hover,
  &:active,
  &:focus,
  &.active {
    .nav-item-url {
      opacity: 0.75;
    }
  }
}

[sidebar-layer] {
  z-index: 1050;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($dark, .5);
}

[sidebar] {
  z-index: 1050;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: .875rem;
  max-width: 17.5rem;
  overflow-y: auto;

  .sidebar-content,
  .sidebar-footer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .close-icon {
    top: 0;
    right: 0;
  }

  .text-normal {
    text-transform: none;
  }

  .border-bottom,
  &.border-right {
    border-color: rgba($gray-300, 0.15) !important;
  }
}
</style>
