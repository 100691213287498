<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin],
  data() {
    return {
      paddingYDefault: 5
    };
  },
  computed: {
    containerClasses() {
      const classes = [];
      if (this.propagateBackground) {
        classes.push("themed-item");
        classes.push(this.paddingY);
      }
      return classes;
    },
    contentClasses() {
      const classes = [];
      if (!this.propagateBackground) {
        classes.push("themed-item");
        classes.push(this.paddingY);
      }
      return classes;
    },
    containerStyles() {
      return {
        "background-image": this.propagateBackground ? this.getContentResponsiveImageURL("background_image") : ""
      };
    },
    contentStyles() {
      return {
        "background-image": this.propagateBackground ? "" : this.getContentResponsiveImageURL("background_image")
      };
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    },
    themedButton() {
      if (this.content.button_class) {
        return this.content.button_class;
      } else {
        return (this.themeType == "light") ? "btn-outline-dark" : "btn-outline-light";
      }
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="bg-cover-center" :class="[contentType, containerClasses]" v-if="isContentVisibleToUser()" :style="[theme, containerStyles]" interaction-trivia-component>
    <div class="container">
      <div class="row justify-content-center bg-cover-center" :class="contentClasses" :style="contentStyles">
        <div class="col-md-8 text-center">
          <!--<reward-marker class="mb-3" :interaction="content.interaction" :content="content" :container-content="containerContent"></reward-marker>-->
          <h2 class="pb-4 mb-0">{{content.question}}</h2>
          <button class="btn btn-lg my-3 px-5 py-3" :class="themedButton" @click="updateCheckInteraction(containerContent, content, content.interaction)" :disabled="!checkRepeatable(containerContent, content, content.interaction) && !ajaxLoading">
            {{content.button ? content.button.title : ft("globals.check")}}
          </button>
        </div>
      </div>
    </div>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <component :is="content.feedback_layout || 'interaction-feedback-alert'" v-if="isInteractionFeedbackPhase(containerContent, content.interaction)" :content="content" :opt-container-content="containerContent" :interaction="content.interaction"></component>
    </transition>
  </div>
</template>

