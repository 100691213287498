<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import Intersect from 'vue-intersect';
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: {
    Intersect
  },
  mounted() {
    Vue.nextTick(() => {
      if (this.videoPopupPresent) {
        // Function prevents video opening by link in the text area
        this.$refs["text-section"].$el.querySelectorAll("a").forEach(function(element) {
          element.addEventListener('click', (e) => { e.stopPropagation(); });
        });
      }
    });
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    toggleVideo(status) {
      if (status) {
        this.video.muted = true;
        this.video.play();
      } else {
        this.video.pause();
      }
    },
    mayToggleVideoPopup() {
      if (this.videoPopupPresent) {
        Vue.set(globalState, "videoPopupContent", { content: this.content, containerContent: this.containerContent });
      }
    }
  },
  computed: {
    videoPopupPresent() {
      return Fandom.exists(this.content.play) || Fandom.exists(this.content.embed_media);
    },
    columnClasses() {
      const size = get(this, ["content", "width"], 8);
      return `col-lg-${size}`;
    },
    alignItems() {
      let align = '';
      switch (get(this, ["content", "vertical_align"], 'center')) {
        case 'bottom':
          align = 'end';
          break;
        case 'top':
          align = 'start';
          break;
        case 'center':
        default:
          align = 'center';
          break;
      }

      return `align-items-${align}`;
    },
    video() {
      return $(this.$refs.video)[0];
    },
    backgroundVideoUrl() {
      const videoDesktop = get(this, "content.background_video.url", false);
      return get(globalState, "viewport.xs", false) ? get(this, "content.background_video_mobile.url", videoDesktop) : videoDesktop;
    }
  }
};
</script>

<template>
  <div
    v-easyadmin="easyadminId" 
    :class="[contentType, paddingY, {'cursor-pointer': videoPopupPresent}]" 
    v-if="isContentVisibleToUser()" 
    :style="[theme, {'background-color': content.background_color}]"
    @click="mayToggleVideoPopup()" 
    heading-media-component
  >
    <div :style="{'background-image': getContentResponsiveImageURL('background_image')}" class="bg-cover-center">
      <intersect @enter="toggleVideo(true)" @leave="toggleVideo(false)" v-if="backgroundVideoUrl">
        <div class="position-absolute-center">
          <video loop muted webkit-playsinline playsinline autoplay ref="video" class="w-100 h-100">
            <source :src="expandUrl(backgroundVideoUrl)" type="video/mp4">
          </video>
        </div>
      </intersect>
      <div :class="content.propagate_container ? 'container-fluid' : 'container'">
        <div class="row justify-content-center">
          <div
            class="d-flex justify-content-center content-container"
            :class="[columnClasses, alignItems, {'mh-100vh-header': !content.height}]"
            :style="[{'min-height': content.height}]"
            >
            <text-section class="w-100" :content="content" :opt-container-content="containerContent" ref="text-section"></text-section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[heading-media-component] {
  position: relative;

  background-color: var(--theme-background);
  color: var(--theme-color);

  video {
    object-fit: cover;
  }
}
</style>
