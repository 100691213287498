<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";
import { mqLess } from 'src/modules/viewport_module';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
    };
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    playVideo() {
      if (this.hasVideo) {
        this.$refs["video"].muted = true;
        this.$refs["video"].play();
      }
    },
    pauseVideo() {
      if (this.hasVideo) {
        this.$refs["video"].pause();
      }
    }
  },
  computed: {
    hasMedia() {
      return Fandom.exists(this.content.image) || this.hasVideo;
    },
    hasVideo() {
      return Fandom.exists(this.content.video);
    },
    height() {
      return get(this, ["content", "height"], "");
    },
    containerStyle() {
      return {
        "background-image": this.backgroundImage
      };
    },
    rootClasses() {
      const paddingY = get(this, ["content", "padding_y"], 4);
      return [
        this.contentType,
        "themed-item"
      ];
    },
    contentClasses() {
      return [
        this.height ? "" : "mh-100vh-header"
      ];
    },
    contentStyle() {
      return {
        "min-height": mqLess("sm") ? "auto" : this.height
      };
    },
    backgroundImage() {
      return this.getContentResponsiveImageURL("background_image");
    },
    containerClass() {
      const propagateContainer = get(this, ["content", "propagate_container"], true);
      return propagateContainer ? "container-fluid" : "container";
    }
  }
};
</script>

<template>
  <intersect @enter="playVideo" @leave="pauseVideo" v-if="isContentVisibleToUser()">
    <div v-easyadmin="easyadminId" :class="rootClasses" :style="theme" :id="anchorId" stripe-bg-video-component>
      <div class="w-100 bg-cover-center pt-5 pb-0 pb-md-5" :style="containerStyle" :class="containerClass">
        <div class="row bg-cover-center position-relative" :class="contentClasses" :style="contentStyle">
          <div class="text-container col-12 col-lg-6 text-column mb-5 mb-md-0">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <text-section :content="content" :opt-container-content="containerContent"></text-section>
              </div>
            </div>
          </div>
          <div class="video-container col-12 col-lg-6 p-0 media-column d-inline-flex align-items-end">
            <div class="embed-responsive embed-responsive-16by9">
              <video class="embed-responsive-item" loop webkit-playsinline playsinline ref="video">
                <source :src="content.video.url" type="video/mp4">
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </intersect>
</template>

<style lang="scss" scoped>
[stripe-bg-video-component] {
  position: relative;

  .row {
    .text-container {
      z-index: 1;
    }

    .video-container {
      position: absolute;
      right: 0;
      bottom: 0px;
    }
  }
}


@include media-breakpoint-down(md) {
  /* Forced no vertical padding on mobile */
  [stripe-bg-video-component] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .row {
      .video-container {
        position: relative;
      }
    }
  }
}

</style>
