<script>
import { get } from "lodash";
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  computed: {
    url() {
      return get(this.content, ['url','url'], Fandom.getContentURL(this.content));
    }
  }
};
</script>

<template>
  <div class="row my-4" v-easyadmin="easyadminId" :class="contentType" tile-podcast-component>
    <div class="col-12 col-md-10 offset-md-1">
      <a :href="url" class="text-white text-decoration-none">
        <div class="media h-100">
          <div class="media-img-container position-relative mr-md-5 mb-4 mb-md-0">
            <img :src="getContentResponsiveImage('thumbnail')">
            <div class="layer position-absolute d-flex justify-content-center align-items-center">
              <i class="fal fa-play-circle fa-5x text-white"></i>
            </div>
          </div>
          <div :style="{'color': content.text_color}" class="media-body d-flex flex-column justify-content-center h-100">
            <h2 class="media-title">{{content.title}}</h2>
            <span class="media-description" v-html="content.description"></span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.media {
  cursor: pointer;

  img {
    max-width: 200px;
  }

  .layer {
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &-title {
    font-size: 2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
  }

  &-description {
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
  }
}

@include media-breakpoint-down(sm) {
  .media {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }

    &-img-container {
      margin-right: 0;
    }

    &-body {
      margin-top: 1rem;
    }
  }
}
</style>
