<script>
import { interactionMixin } from 'src/modules/interactions_module.js';
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  props: {
    completed: {
      type: Boolean,
      default: false
    },
    showResult: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contentResult: null
    };
  },
  mounted() {
    if (this.completed) {
      this.updateTestInteraction(this.containerContent, this.content, this.content.interaction, null, this.updateInteractionSuccessCallback)
    }
  },
  mixins: [interactionMixin, fandomBasicMixin, fandomExtendedMixin],
  methods: {
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    updateInteractionSuccessCallback(data, reset=false) {
      this.contentResult = data.content_result;
      if (reset) {
        this.$emit("reset-test");
      }

      Vue.nextTick(() => window.dispatchEvent(new Event('resize')));
    }
  },
  computed: {
    loading() {
      return this.content.loading;
    }
  },
  watch: {
    showResult(newVal, oldVal) {
      if (newVal !== oldVal && newVal && this.completed) {
        setTimeout(() => {
          this.updateTestInteraction(this.containerContent, this.content, this.content.interaction, null, this.updateInteractionSuccessCallback)
        }, 2000);
      }
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="w-100 h-100" :class="contentType" v-if="isContentVisibleToUser()" test-interaction-component>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <div v-if="contentResult">
        <component :is="contentResult.layout" :content="contentResult" :opt-container-content="containerContent">
          <template #stripe-two-col-custom-text>
            <div class="container more-info-box p-4">
              <h3>{{ft("test.result_info_box.title")}}</h3>
              <span class="d-block description text-dark mb-4">{{ft("test.result_info_box.description")}}</span>
              <div class="button-container row px-3 justify-content-center">
                <div class="col-9 p-0 mb-3">
                  <a 
                    v-if="content.show_more_button" 
                    class="btn shadow-none w-100 px-2"
                    :class="getPrimaryButtonClass(content.show_more_button_class, content.theme)" 
                    :target="getTarget(content.show_more_button)" 
                    :href="content.show_more_button.url"
                  >
                    {{content.show_more_button.title}}
                  </a>
                </div>
                <div class="col-9 p-0">
                  <a
                    class="btn shadow-none btn-primary w-100 px-2"
                    href="#"
                    @click.prevent="resetTestInteraction(containerContent, content, content.interaction, null, updateInteractionSuccessCallback)"
                  >
                    {{ft("test.result_info_box.reset_button")}}
                  </a>
                </div>
              </div> 
            </div>
          </template>
        </component>
      </div>
    </transition>
    <div class="row loading-container justify-content-center align-items-center flex-column text-center" v-if="!contentResult">
      <div class="col-12">
        <component v-if="loading.title" :class="loading.title_class || 'h1'" :is="loading.title_tag || 'h2'" :style="{'color': loading.text_color}" title>
          {{loading.title}}
        </component>
        <img v-if="loading.image" class="col-12 col-lg-6 image mt-1 mb-1" :alt="loading.image.alt" :src="loading.image.url">
        <component class="pt-3" :is="loading.description_tag || 'p'" :class="loading.description_class" v-if="loading.description" :style="{'color': `${loading.text_color}`}" v-html="loading.description" description></component>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[test-interaction-component] {
  .loading-container {
    .image {
      width: 80%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .more-info-box {
    background-color: #f3f8f5;
  }

  ::v-deep [two-col-component] {
    .bg-cover-center {
      @include media-breakpoint-between(sm, md) {
        align-items: center;

        .media-column {
          max-width: 60%;
        }
      }

      .media-column {
        @include media-breakpoint-up(lg) {
          padding-top: 5rem !important;
        }
      }
    }
      
    .media-column {
      align-items: start !important;
    }

    @include media-breakpoint-down(sm) {
      [description] p, [description] li span, .category {
        font-size: 1rem !important;
      }
    }
  }
}
</style>
