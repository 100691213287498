<template>
  <div v-if="showTest" v-easyadmin="easyadminId" class="themed-item" :class="[contentType]" :style="[contentStyle, theme]" list-carousel-interaction-component>
    <div class="carousel-header d-flex justify-content-between align-items-center">
      <div class="logo pl-3 pl-md-4">
        <img :src="getContentResponsiveImage('icon')" class="logo"/>
      </div>
      <div v-if="countAnswer <= totalQuestions && Math.floor(currentIndex/2)+1 <= totalQuestions" class="w-100 position-absolute d-flex progress-container align-items-center justify-content-center">
        <div class="progress d-none d-md-flex">
          <div :style="progressStyle" class="progress-bar" role="progressbar"></div>
        </div>
        <span class="progress-count p-0 pl-sm-2 text-center">
          {{ Math.floor(currentIndex/2)+1 }} / {{ totalQuestions }}
        </span>
      </div>
      <div class="close-icon icon pr-3 pr-md-4">
        <i class="fal fa-times fa-2x cursor-pointer" @click="handleClose"></i>
      </div>
    </div>
    <div class="carousel-body position-relative">
      <div class="row">
        <div class="col-12">
          <div class="flickity-carousel" ref="flickity-carousel" :class="skinSet">
            <div
              class="flickity-carousel-item justify-content-center"
              v-for="(item, index) in content.items" 
              :key="`carousel-slide-${index}`"
            >
              <div class="container d-flex w-100 py-sm-0 py-3 align-items-md-center justify-content-center">
                <component 
                  :is="item.layout"
                  :content="item"
                  :opt-container-content="containerContent"
                  :reset="resetAnswer"
                  @user-interaction-updated="interactionHandler"
                  @reset-test="handleReset"
                  :completed="countAnswer===totalQuestions"
                  :show-result="currentIndex===content.items.length-1"
                >
                  <template #stripe-text-custom-text>
                    <div class="text-center py-4">
                      <button @click="slider.next()" class="btn btn-primary">Prosegui</button>
                    </div>
                  </template>
                </component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from 'src/modules/interactions_module.js'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin],
  data: function () {
    return { 
      slider: null,
      interactionHandlerTimeout: null,
      countAnswer: 0,
      resetAnswer: false,
      showTest: false,
      currentIndex: 0
    };
  },
  mounted() {
    Fandom.onEvent("start-test", this.startTest);
    const contentUserInfo = this.getContentUserInfo(this.containerContent);
    Object.keys(contentUserInfo.id_to_interaction).forEach( key => {
      const interaction = contentUserInfo.id_to_interaction[key];
      if (interaction.interaction_type === "Trivia" && this.exists(interaction.user_interaction)) {
        this.countAnswer++;
      }
    })
  },
  methods: {
    startTest() {
      document.getElementsByTagName('body')[0].classList.add("overflow-hidden");
      document.getElementsByClassName('footer')[0].classList.add("d-none");
      $('.main-container div').toggleClass("show-test");

      this.showTest = true;
      Vue.nextTick(() => {
        this.mountCarousel();

        [...document.getElementsByClassName('carousel-body')].forEach(item => {
          disableBodyScroll(item);
        })
      });
    },
    getContentResponsiveImage(name, customContent = false) {
      const content = customContent ? customContent : this.content;
      if ((globalState.viewport["xs"] || globalState.viewport["sm"] || globalState.viewport["md"]) && this.exists(content[`${name}_mobile`])) {
        return this.expandUrl(content[`${name}_mobile`].url);
      } else if (this.exists(content[name])) {
        return this.expandUrl(content[name].url);
      }
      return null;
    },
    handleClose() {
      this.showTest = false;
      document.getElementsByTagName('body')[0].classList.remove("overflow-hidden");
      document.getElementsByClassName('footer')[0].classList.remove("d-none");
      $('.main-container div').toggleClass("show-test");
      clearAllBodyScrollLocks();
    },
    mountCarousel() {
      this.currentIndex = this.countAnswer*2;

      const slideConfig =  {
        draggable: false,
        prevNextButtons: false,
        contain: true,
        pageDots: false,
        wrapAround: false,
        cellAlign: "center",
        groupCells: true,
        initialIndex: this.currentIndex,
        adaptiveHeight: true
      }

      this.slider = new Flickity(this.$refs["flickity-carousel"], slideConfig);

      this.slider.on("change", (index) => {
        clearTimeout(this.interactionHandlerTimeout);
        this.interactionHandlerTimeout = null;
        if (index > 0) {
          this.currentIndex = index;
        }
        $(".carousel-body").scrollTop = 0;
      });

    },
    interactionHandler(data) {
      if (data.interaction.type != "Test") {
        this.interactionHandlerTimeout = setTimeout(() => {
          this.countAnswer++;
          this.interactionHandlerTimeout = null;
          this.slider.next();
        }, 500);
      }
    },
    handleReset() {
      this.countAnswer = 0;
      this.resetAnswer = true;
      this.currentIndex = 0;
      Vue.nextTick(() => {
        this.slider.select(0, false, true);
        this.resetAnswer = false;
      })
    }
  },
  computed: {
    skin() {
      return this.content.skin || "1";
    },
    skinSet() {
      const classes = [`stripe-carousel__skin-${this.skin}`];
      return classes;
    },
    contentStyle() {
      return {
        "background-image": this.getContentResponsiveImageURL("background_image")
      };
    },
    totalQuestions() {
      return this.content.items.reduce((acc, item) => {
        return acc += item.interaction && item.interaction.type === 'Trivia' ? 1 : 0
      }, 0);
    },
    progressStyle() {
      return {
        width: `${this.countAnswer / this.totalQuestions * 100}%`
      }
    }
  }
};
</script>

<style lang="scss">
[list-carousel-interaction-component] {
  position: fixed;
  background-size: 100% auto;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1031;

  .carousel-header {
    background-color: $white;
    width: 100vw;
    height: 90px;
    
    .logo {
      height: 30px;
    }

    .progress {
      height: 6px;
      background-color: #67A787;
      width: 40%;
    }

    .close-icon {
      z-index: 1;
    }
  }

  .carousel-body {
    height: calc(100% - 90px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .flickity {
    &-viewport, &-carousel {
      &:focus {
        outline: none !important;
      }
    }
  }

  .flickity-button.flickity-prev-next-button {
    z-index: 2;
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    background: transparent;
    width: 56px;
    height: 56px;
  }

  .flickity-button.flickity-prev-next-button:disabled {
    display: none;
  }

  .flickity-button-icon {
    transform: scale(.65);
  }

  .flickity-page-dots {
    padding-bottom: 3rem;
    position: relative;
    bottom: 0;

    .dot {
      width: 16px;
      height: 16px;
      background: var(--theme-color);
      opacity: 0.35;
      
      &.is-selected {
        opacity: 1;
      }
    }
  }

  .flickity-carousel-item {
    padding-left: 1rem;
    padding-right: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: calc(100vh - 90px);

    > div {
      position: relative;
    }
  }

  .stripe-carousel__skin-1 .flickity-carousel-item {
    width: 100%;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  @include media-breakpoint-down(sm) {

    .flickity-viewport::after,
    .flickity-viewport::before {
      width: 2rem;
    }

    .stripe-carousel__skin-1 .flickity-carousel-item {
      padding-left: 0rem;
      padding-right: 0rem;
      width: 100%;
    }

    .flickity-button.flickity-prev-next-button {
      display: none;
    }

  }

  [two-col-component] {
    .text-column {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  [stripe-text-component] {
    align-items: center;
    display: flex;

    [title], [description] {
      text-align: left;
    }
    
    .bg-cover-center {
      border-radius: 24px;
    }
  }
}
</style>
