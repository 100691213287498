<script>
import { interactionMixin } from "src/modules/interactions_module.js";
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data() {
    return {};
  },
  methods: {
    providerIcon(provider) {
      if (provider == "facebook") {
        return "fab fa-facebook-f";
      } else {
        return `fab fa-${provider.replace(/_/, "-")}`;
      }
    }
  },
  computed: {
    canUseWebShare() {
      return "share" in navigator;
    },
    shareRecord() {
      return get(this.content, "interactions.share", null);
    },
    shareInteraction() {
      return this.shareRecord.interaction;
    },
    shareProviders() {
      return this.shareRecord && Fandom.exists(this.shareRecord.providers) ? this.shareRecord.providers.split(",") : [];
    }
  }
};
</script>

<template>
  <div class="row justify-content-center text-primary" v-if="shareRecord && shareProviders.length > 0" interaction-share-component>
    <div class="col-auto d-flex">
      <template v-if="!canUseWebShare">
        <div
          class="provider-icon d-flex align-items-center justify-content-center mx-2"
          v-for="(provider, index) in shareProviders"
          :key="index"
          @click="updateShareInteraction(containerContent, content, shareInteraction, provider, false, false, canUseWebShare)"
          >
          <i class="fa-2x" :class="providerIcon(provider)"></i>
        </div>
      </template>
      <div class="provider-icon text-center" @click="updateShareInteraction(containerContent, content, shareInteraction, 'webShare', false, false, canUseWebShare)" v-else>
        <i class="fa-2x fal fa-share-alt"></i>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[interaction-share-component] {
  .provider-icon {
    border: 1px solid $primary;
    border-radius: 100%;
    height: 4rem;
    width: 4rem;
    cursor: pointer;
    i { line-height: 4rem };
  }

}
</style>
