<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get, omit } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      paddingYDefault: 5
    };
  },
  methods: {
    omit: omit
  },
  computed: {
    height() {
      return get(this, ["content", "height"], "");
    },
    containerStyle() {
      return {
        "background-image": this.propagateBackground ? this.backgroundImage : false
      };
    },
    rootClasses() {
      const paddingY = get(this, ["content", "padding_y"], 4);
      return [
        this.contentType,
        this.paddingY,
        this.propagateBackground ? "themed-item" : ""
      ];
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    },
    contentClasses() {
      const mobileDirection = this.content.reverse_mobile ? "flex-column-reverse" : "flex-column";
      const desktopDirection = this.content.reverse ? "flex-lg-row-reverse" : "flex-lg-row";
      return [
        mobileDirection,
        desktopDirection,
        this.propagateBackground ? "" : "themed-item"
      ];
    },
    contentStyle() {
      const height = this.height.indexOf("$") !== 0 ? this.height : null;
      return {
        "min-height": height,
        "background-image": this.propagateBackground ? false : this.backgroundImage
      };
    },
    textColumnWidth() {
      const size = get(this, ["content", "text_column_width"], 6);
      return parseInt(size);
    },
    titleColumnClasses() {
      return `col-lg-${12 - this.textColumnWidth}`;
    },
    textColumnClasses() {    
      return `col-lg-${this.textColumnWidth}`;
    },
    backgroundImage() {
      return this.getContentResponsiveImageURL("background_image");
    },
    containerClass() {
      const propagateContainer = get(this, ["content", "propagate_container"], false);
      return propagateContainer ? "container-fluid" : "container";
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="rootClasses" :style="theme" :id="anchorId" v-if="isContentVisibleToUser()" two-col-component>
    <div class="w-100 bg-cover-center" :style="containerStyle">
      <div :class="containerClass">
        <div class="row py-4" :class="contentClasses" :style="contentStyle">
          <div class="col-12 title-column" :class="titleColumnClasses">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-10">
                <component v-if="content.title" :class="content.title_class || 'h1'" :is="content.title_tag || 'h2'" :style="{'color': content.text_color}" v-html="content.title"></component>
              </div>
            </div>
          </div>
          <div class="col-12 text-column" :class="textColumnClasses">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-10">
                <text-section :content="omit(content, ['title'])" :opt-container-content="containerContent"></text-section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
