<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return { };
  },
  computed: {
    rootStyle() {
      return {
        "background-image": this.getContentResponsiveImageURL("background_image"),
        "background-color": this.content.background_color
      };
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="py-5" v-if="isContentVisibleToUser()" :style="rootStyle" page-article-news-component>
    <div class="container text-primary pb-5">
      <div class="row justify-content-center">
        <div class="col-md-8 text-center pb-5">
          <h1 v-html="content.title" class="display-1 font-weight-bold" :class="{'pb-4': content.subtitle}"></h1>
          <h2 v-if="content.subtitle" v-html="content.subtitle" class="font-weight-normal h3"></h2>
        </div>
        <div class="col-md-10 pb-5" v-if="content.image">
          <img class="w-100" :alt="content.title" :src="content.image.url">
        </div>
        <div class="col-md-8 pb-5">
          <interaction-share :content="content" :opt-container-content="containerContent"></interaction-share>
        </div>
        <div class="col-md-8" v-html="content.description"></div>
      </div>
    </div>
    <div v-if="content.related_content">
      <div class="container text-primary text-center">
        <div class="row justify-content-center">
          <div class="col"><h3 class="h1 pb-5">{{ft("news.related")}}</h3></div>
        </div>
      </div>
      <component :is="content.related_content.layout" :content="content.related_content" :opt-container-content="containerContent"></component>
    </div>
  </div>
</template>

<style lang="scss">
[page-article-news-component] {
  background-size: 100% auto;
}
</style>
