<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      contentFiltering: [],
    }
  },
  mounted() {
    this.initFilters();
    if (this.content.$id) {
      console.log(`%Warning\n\tmessage: contents with layout 'grid-filter' should be standalone contents, in order to prevent depth conflicts`, "color: yellow");
    }
  },
  methods: {
    initFilters() {
      this.contentFiltering = [];
      this.content.items.forEach(filter => {
        Vue.set(this.contentFiltering, this.contentFiltering.length, []);
      });
    },
    isFilterActive(filter, topIndex) {
      return (this.contentFiltering[topIndex] || []).includes(filter);
    },
    toggleFilter(filter, topIndex) {
      const filterInnerIndex = this.contentFiltering[topIndex].indexOf(filter);
      if (filterInnerIndex > -1) {
        this.contentFiltering[topIndex].splice(filterInnerIndex,1);
      } else {
        this.contentFiltering[topIndex].push(filter);
      }

      Fandom.emit('grid-filter-apply', {
        contentFiltering: this.contentFiltering,
        handlerContents: (this.content.handler_contents || "")
      });
    },
  },
  computed: {
    anyFilterActive() {
      return this.contentFiltering.flat().length > 0;
    }
  }
};

</script>

<template>
  <div 
    v-if="isContentVisibleToUser()" 
    v-easyadmin="easyadminId" 
    :class="[contentType, paddingY]"
    grid-filter-component
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex mx-n1">
            <div class="dropdown mx-1" v-for="(item, topIndex) in content.items" :key="`f-${item.$id}`">
              <button class="btn btn-primary" type="button" :id="`f-${item.$id}`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{item.title}}<i class="ml-3 mt-1 far fa-angle-down"></i>
              </button>
              <div class="dropdown-menu" :aria-labelledby="`f-${item.$id}`">
                <a class="dropdown-item" v-for="(filter, index) in item.filter_contents" href="javascript:void(0)" @click="toggleFilter(filter, topIndex)" :class="{'active': isFilterActive(filter, topIndex)}">{{getContent(filter).title}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3" v-if="anyFilterActive">
        <div class="col-12">
          <div class="d-flex mx-n1">
            <div v-for="(item, topIndex) in contentFiltering" :key="`f-preview-${item.join('_')}-${topIndex}`" v-if="item.length > 0">
              <button class="mx-1 my-1 btn btn-secondary btn-sm" v-for="(filter, index) in item" @click="toggleFilter(filter, topIndex)">
                {{getContent(filter).title}}<i class="ml-3 mt-1 fal fa-times"></i>
              </button>
            </div>
          </div>
        </div>    
      </div>
    </div> 
  </div>
</template>
