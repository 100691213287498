<template>
  <div footer-component class="footer">
    <component v-if="content.stripes" :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="content"></component>
    <div class="container">
      <div class="d-none d-md-block">
        <div class="row footer-row">
          <div class="col-12 d-flex my-4 align-items-center">
            <div v-if="content.logo" class="footer-logo bg-contain-center" :style="{'background-image': `url('${content.logo.url}')`}"></div>
            <div class="row w-100 h-100 flex-column justify-content-around px-2 ml-3">
              <div class="h-100 py-2 d-flex justify-content-between ml-5">
                <div class="navbar-link-container d-flex flex-column justify-content-between">
                  <a
                    class="navbar-link position-relative"
                    v-for="(item, index) in content.navbar_bottom"
                    :href="item.url.url"
                    :target="getTarget(item.url)"
                    :key="`bottom-${index}`"
                    >
                    {{item.url.title}}
                  </a>
                </div>
                <div class="social-icons-wrapper float-right d-flex" v-for="(navbar, index) in content.navbar_social" :key="index">
                  <a
                    v-for="(icon, iconIndex) in navbar.items"
                    :key="`navbar-${index}-icon-${iconIndex}`"
                    :href="icon.url.url"
                    class="social-icon d-flex justify-content-center text-decoration-none px-2"
                    :target="getTarget(icon.url)"
                    >
                    <i class="fab" :class="icon.icon"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-sm-block d-md-none">
        <div class="row py-4">
          <div class="col-12 mb-3">
            <div v-if="content.logo" class="footer-logo bg-contain-center" :style="{'background-image': `url('${content.logo.url}')`}"></div>
          </div>
          <div class="col-12 d-flex flex-column my-3">
            <a
              class="navbar-link position-relative mb-3"
              v-for="(item, index) in content.navbar_bottom"
              :href="item.url.url"
              :target="getTarget(item.url)"
              :key="`bottom-${index}`"
              >
              {{item.url.title}}
            </a>
          </div>
          <div class="col-12 social-icons-wrapper d-flex align-items-center" v-for="(navbar, index) in content.navbar_social" :key="index">
            <a
              v-for="(icon, iconIndex) in navbar.items"
              :key="`navbar-${index}-icon-${iconIndex}`"
              :href="icon.url.url"
              class="social-icon d-flex align-items-center text-decoration-none pr-3"
              :target="getTarget(icon.url)"
              >
              <i class="fab" :class="icon.icon"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getTarget: Fandom.getTarget
  }
};
</script>

<style lang="scss">
[footer-component] {
  background-color: $primary;

  $social-icon-size: 1.25rem;

  .social-icons-wrapper {
    @include media-breakpoint-up(lg) {
      justify-content: end;
    }

    @include ie {
      display: flex;
      justify-content: flex-end;
    }
  }

  .social-icon {
    font-size: $social-icon-size;
    line-height: 1;
    color: $white;

    @include ie {
      margin: 1rem 1.5rem;
    }
  }

  .footer-logo {
    width: 6.5rem;
    height: 6.5rem;
  }

  .navbar-link {
    color: $white;
    font-weight: 900;
    font-size: 1.5rem;
  }

  .navbar-link {
    font-size: 1rem;
  }

  @include media-breakpoint-down(sm) {
    .navbar-link {
      font-size: 0.875rem;
    }

    .footer-logo {
      width: 4.5rem;
      height: 4.5rem;
    }
  }
}
</style>
