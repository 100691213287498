<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin],
  props: {
    reset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userChoice: null,
      paddingYDefault: 5,
      userInteraction: null,
    };
  },
  mounted() {
    if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
      this.userInteraction = this.getUserInteraction(this.containerContent, this.content.interaction);
      this.userChoice = this.userInteraction.data.answer_id;
    }
  },
  methods: {
    getAnswerClasses(answer) {
      if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
        this.userInteraction = this.getUserInteraction(this.containerContent, this.content.interaction);
        return this.userInteraction.data.answer_id == answer.$id ? "choose" : "not-choose";
      }
    },
    handleAnswerClick(answerId) {
      this.userChoice = answerId;
      this.updateTriviaInteraction(this.containerContent, this.content, this.content.interaction, answerId);
    }
  },
  computed: {
    containerStyles() {
      return {
        "background-image": this.propagateBackground ? this.getContentResponsiveImageURL("background_image") : ""
      };
    },
    contentStyles() {
      return {
        "background-image": this.propagateBackground ? "" : this.getContentResponsiveImageURL("background_image")
      };
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    },
    height() {
      return get(this, ["content", "height"], "");
    },
    mediaImage() {
      return this.getContentResponsiveImageURL("image");
    },
    aspectRatioClasses() {
      return this.height.indexOf("$") === 0 ? this.height.replace("$", "ar-") : "ar-1by1";
    },
  },
  watch: {
    reset(newVal) {
      if (newVal) {
        this.userChoice = null;
      }
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="bg-cover-center themed-item w-100" :style="[theme]" v-if="isContentVisibleToUser()" interaction-trivia-component>
    <div class="w-100 bg-cover-center" :style="containerStyles">
      <div>
        <div class="row justify-content-center" :style="contentStyles">
          <div class="image-column col-lg-6 pb-4 pb-sm-0">
            <div
              class="bg-cover-center w-100 position-relative"
              :style="{'background-image': mediaImage}" :class="aspectRatioClasses"
            ></div>
          </div>
          <div class="col-12 text-column col-lg-6">
            <component v-if="content.question" :class="content.question_class || 'h2'" :is="content.question_tag || 'h2'" :style="{'color': content.text_color}" v-html="content.question"></component>
              <div class="my-1 my-lg-3 p-3 answer" :class="getAnswerClasses(answer)" v-for="answer in content.answers" :key="`${content.$id}-${answer.$id}`" @click="handleAnswerClick(answer.$id)">
                <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                  <div class="custom-control custom-radio">
                    <input 
                      type="radio"
                      :id="`customRadio-${content.$id}-${answer.$id}`"
                      :name="`customRadio-${content.$id}`"
                      class="custom-control-input"
                      :value="answer.$id"
                      v-model="userChoice"
                      :disabled="userChoice!==null"
                    >
                    <label 
                      class="custom-control-label pl-3 text-dark"
                      :for="`customRadio-${content.$id}-${answer.$id}`"
                      >{{ answer.text }}
                    </label>
                  </div>
                </transition>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[interaction-trivia-component] {
  .image-column {
    max-width: 570px;
  }

  .answer {
    cursor: pointer;
    position: relative;
    border: 1px solid $white;

    &:hover:not(.not-choose) {
      border: 1px solid $primary;
    }

    &.choose {
      border: 1px solid $primary;
      background-color: $primary;
      color: $white !important;

      .custom-control {
        &-input[disabled], &-input:disabled {
          ~ .custom-control-label {
            color: $white !important;
         
            &::before {
              border-color: $white;
              background-color: $primary !important; 
            }
          }
        }
      }
    }

    .custom-control {
      padding-left: 2rem;

      &-input[disabled], &-input:disabled {
        ~ .custom-control-label {
          color: $dark !important;
        }
      }

      &-label {
        cursor: pointer;

        &::before {
          border-color: $primary;
          background-color: $white !important; 
        }

        &::before, &::after {
          left: -2rem;
          width: 2rem;
          height: 2rem;
          top: calc(50% - 1rem);
        }
      }
    }
  }
}
</style>
